export const QUESTION_CONSTRAINTS = {
    MIN_CHAR: 10,
    MAX_CHAR: 750,
    MAX_CODING_CHAR: 2000,
};

export const ERROR_MESSAGES = {
    MIN_CHAR_ERROR: `Question must be at least ${QUESTION_CONSTRAINTS.MIN_CHAR} characters long`,
    MAX_CHAR_ERROR: `Question must be not more than ${QUESTION_CONSTRAINTS.MAX_CHAR} characters long`,
    MAX_CODING_CHAR_ERROR: `Question must be not more than ${QUESTION_CONSTRAINTS.MAX_CODING_CHAR} characters long`,
    SAVE_QUESTION: 'Please save the question to continue',
    SAVE_ERROR: 'Please save the question(s) to continue',
    EMPTY_QUESTION: "Question can't be empty",
    EMPTY_ANSWER: "Answer can't be empty",
    QUESTION_ALREADY_EXISTS: 'Question already exists',
    TOTAL_QUESTION_LIMIT: "Total question can't be more than 20",
    QUESTIONS_NOT_AVAILABLE: 'Questions are not available',
    SELECT_POSITION: 'Please select position',
    ADD_CUSTOM_QUESTION: 'Please save your last question before adding another custom question',
    MINIMUM_QUESTION_LENGTH: 'Minimum 5 questions are required to continue',
};

export const QUESTION_TYPES = {
    GLOBAL: 'global',
    CUSTOM: 'custom',
    CODING: 'coding',
    AI_GENERATED: 'ai-generated',
};

export const QUESTION_TYPE_LABEL = {
    AI: 'AI',
    CODING: 'Coding',
    CUSTOM: 'Custom',
};

export const DIFFICULTY_LEVELS = {
    EASY: 'Easy',
    MEDIUM: 'Medium',
    HARD: 'Hard',
};

export const PROGRAMMING_LANGUAGES = {
    NA: 'No Preference',
    ASSEMBLY: 'Assembly',
    BASH: 'Bash',
    BASIC: 'Basic',
    C: 'C',
    CLOJURE: 'Clojure',
    COBOL: 'COBOL',
    CPP: 'C++',
    CSHARP: 'C#',
    D: 'D',
    DART: 'Dart',
    ELIXIR: 'Elixir',
    ERLANG: 'Erlang',
    FSHARP: 'F#',
    FORTRAN: 'Fortran',
    GO: 'Go',
    GROOVY: 'Groovy',
    HASKELL: 'Haskell',
    JAVA: 'Java',
    JAVASCRIPT: 'Javascript',
    KOTLIN: 'Kotlin',
    LUA: 'Lua',
    OCAML: 'OCaml',
    OCTAVE: 'Octave',
    PASCAL: 'Pascal',
    PERL: 'Perl',
    PHP: 'PHP',
    PROLOG: 'Prolog',
    PYTHON: 'Python',
    R: 'R',
    RUBY: 'Ruby',
    RUST: 'Rust',
    SCALA: 'Scala',
    SQL: 'SQL',
    SWIFT: 'Swift',
    TEXT: 'Plain Text',
    TYPESCRIPT: 'TypeScript',
    VB: 'Visual Basic',
};

export const CODE_DURATIONS = [
    { value: 900, label: '15 mins' },
    { value: 1200, label: '20 mins' },
    { value: 1500, label: '25 mins' },
    { value: 1800, label: '30 mins' },
    { value: 2100, label: '35 mins' },
    { value: 2400, label: '40 mins' },
    { value: 2700, label: '45 mins' },
];

export const CODE_DURATIONS_SQL = [
    { value: 300, label: '5 mins' },
    { value: 600, label: '10 mins' },
    { value: 900, label: '15 mins' },
    { value: 1200, label: '20 mins' },
    { value: 1500, label: '25 mins' },
];

export const CODE_DURATIONS_RANGE = (level) => {
    let tempCodingDuration = [
        { value: 900, label: '15 mins' },
        { value: 1200, label: '20 mins' },
        { value: 1500, label: '25 mins' },
        { value: 1800, label: '30 mins' },
        { value: 2100, label: '35 mins' },
        { value: 2400, label: '40 mins' },
        { value: 2700, label: '45 mins' },
    ];
    if (level === DIFFICULTY_LEVELS.MEDIUM) {
        tempCodingDuration = tempCodingDuration.filter((data) => data?.value >= 1800);
    } else if (level === DIFFICULTY_LEVELS.HARD) {
        tempCodingDuration = tempCodingDuration.filter((data) => data?.value >= 2400);
    }
    return tempCodingDuration;
};

export const DEFAULT_GENERATE_QUESTIONS = {
    question: '',
    codeBlock: '',
    answer: '',
    questionType: QUESTION_TYPES.CUSTOM,
    timeToPrepare: 10,
    timeToAnswer: 120,
};

export const CODING_TYPE = {
    CODING_LIBRARY: 'Coding Library',
    CUSTOM_QUESTION: 'Custom Question',
};

export const CODING_TYPE_OPTIONS = [
    { value: CODING_TYPE.CODING_LIBRARY, label: CODING_TYPE.CODING_LIBRARY },
    { value: CODING_TYPE.CUSTOM_QUESTION, label: CODING_TYPE.CUSTOM_QUESTION },
];

export const DEFAULT_GENERATE_CODING_QUESTIONS = {
    question: '',
    answer: '',
    programmingLanguage: PROGRAMMING_LANGUAGES.NA,
    questionType: QUESTION_TYPES.CODING,
    timeToPrepare: 0,
    timeToAnswer: CODE_DURATIONS[0].value,
    difficultyLevel: DIFFICULTY_LEVELS.EASY,
    questionSource: CODING_TYPE.CODING_LIBRARY,
};

export const QUESTION = {
    QUESTION: 'question',
    CODE_BLOCK: 'codeBlock',
    QUESTION_ID: 'questionId',
    EDITABLE_ANSWER: 'editableAnswer',
    ANSWER: 'answer',
    QUESTION_TYPE: 'questionType',
    TIME_TO_PREPARE: 'timeToPrepare',
    TIME_TO_ANSWER: 'timeToAnswer',
    IS_EDITABLE: 'isEditable',
    EDITABLE_QUESTION: 'editableQuestion',
    ERROR: 'error',
    DIFFICULTY_LEVEL: 'difficultyLevel',
    PROGRAMMING_LANGUAGE: 'programmingLanguage',
    MCQ_TYPE: 'mcqType',
    NO_OF_QUESTIONS: 'noOfQuestions',
    MCQ_TIME_DURATION: 'mcqTimeDuration',
    CODING_TYPE: 'questionSource',
};

export const NO_OF_QUESTIONS_LIST = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
    { value: 13, label: '13' },
    { value: 14, label: '14' },
    { value: 15, label: '15' },
    { value: 16, label: '16' },
    { value: 17, label: '17' },
    { value: 18, label: '18' },
    { value: 19, label: '19' },
    { value: 20, label: '20' },
];

export const MCQ_TIME_DURATION_LIST = [
    { value: 60, label: '1 min/Q' },
    { value: 90, label: '1.5 mins/Q' },
    { value: 120, label: '2 mins/Q' },
    { value: 150, label: '2.5 mins/Q' },
    { value: 180, label: '3 mins/Q' },
];

export const MCQ_TYPE_QUESTIONS = [
    { value: 'logical_reasoning', label: 'Logical Reasoning' },
    { value: 'quantitative', label: 'Quantitative' },
    { value: 'number_system', label: 'Numerical Reasoning' },
    { value: 'verbal_reasoning', label: 'Verbal Reasoning' },
];

export const MCQ_TYPE_TEXT = {
    logical_reasoning: 'Logical Reasoning',
    quantitative: 'Quantitative',
    number_system: 'Numerical Reasoning',
    verbal_reasoning: 'Verbal Reasoning',
};

export const QUESTION_TYPE_OPTIONS = [
    { value: QUESTION_TYPES.CUSTOM, label: 'Custom Question' },
    { value: QUESTION_TYPES.CODING, label: 'Coding Question' },
];

export const QUESTION_TYPE_OPTIONS_CODING_DISABLED = [
    { value: QUESTION_TYPES.CUSTOM, label: 'Custom Question', isDisabled: false },
    { value: QUESTION_TYPES.CODING, label: 'Coding Question', isDisabled: true },
];

export const DIFFICULTY_LEVEL_OPTIONS = [
    { value: DIFFICULTY_LEVELS.EASY, label: DIFFICULTY_LEVELS.EASY },
    { value: DIFFICULTY_LEVELS.MEDIUM, label: DIFFICULTY_LEVELS.MEDIUM },
    { value: DIFFICULTY_LEVELS.HARD, label: DIFFICULTY_LEVELS.HARD },
];
export const PROGRAMMING_LANGUAGE_OPTIONS = [
    { value: PROGRAMMING_LANGUAGES.NA, label: PROGRAMMING_LANGUAGES.NA },
    { value: PROGRAMMING_LANGUAGES.ASSEMBLY, label: PROGRAMMING_LANGUAGES.ASSEMBLY },
    { value: PROGRAMMING_LANGUAGES.BASH, label: PROGRAMMING_LANGUAGES.BASH },
    { value: PROGRAMMING_LANGUAGES.BASIC, label: PROGRAMMING_LANGUAGES.BASIC },
    { value: PROGRAMMING_LANGUAGES.C, label: PROGRAMMING_LANGUAGES.C },
    { value: PROGRAMMING_LANGUAGES.CLOJURE, label: PROGRAMMING_LANGUAGES.CLOJURE },
    { value: PROGRAMMING_LANGUAGES.COBOL, label: PROGRAMMING_LANGUAGES.COBOL },
    { value: PROGRAMMING_LANGUAGES.CPP, label: PROGRAMMING_LANGUAGES.CPP },
    { value: PROGRAMMING_LANGUAGES.CSHARP, label: PROGRAMMING_LANGUAGES.CSHARP },
    { value: PROGRAMMING_LANGUAGES.D, label: PROGRAMMING_LANGUAGES.D },
    { value: PROGRAMMING_LANGUAGES.DART, label: PROGRAMMING_LANGUAGES.DART },
    { value: PROGRAMMING_LANGUAGES.ELIXIR, label: PROGRAMMING_LANGUAGES.ELIXIR },
    { value: PROGRAMMING_LANGUAGES.ERLANG, label: PROGRAMMING_LANGUAGES.ERLANG },
    { value: PROGRAMMING_LANGUAGES.FSHARP, label: PROGRAMMING_LANGUAGES.FSHARP },
    { value: PROGRAMMING_LANGUAGES.FORTRAN, label: PROGRAMMING_LANGUAGES.FORTRAN },
    { value: PROGRAMMING_LANGUAGES.GO, label: PROGRAMMING_LANGUAGES.GO },
    { value: PROGRAMMING_LANGUAGES.GROOVY, label: PROGRAMMING_LANGUAGES.GROOVY },
    { value: PROGRAMMING_LANGUAGES.HASKELL, label: PROGRAMMING_LANGUAGES.HASKELL },
    { value: PROGRAMMING_LANGUAGES.JAVA, label: PROGRAMMING_LANGUAGES.JAVA },
    { value: PROGRAMMING_LANGUAGES.JAVASCRIPT, label: PROGRAMMING_LANGUAGES.JAVASCRIPT },
    { value: PROGRAMMING_LANGUAGES.KOTLIN, label: PROGRAMMING_LANGUAGES.KOTLIN },
    { value: PROGRAMMING_LANGUAGES.LUA, label: PROGRAMMING_LANGUAGES.LUA },
    { value: PROGRAMMING_LANGUAGES.OCAML, label: PROGRAMMING_LANGUAGES.OCAML },
    { value: PROGRAMMING_LANGUAGES.OCTAVE, label: PROGRAMMING_LANGUAGES.OCTAVE },
    { value: PROGRAMMING_LANGUAGES.PASCAL, label: PROGRAMMING_LANGUAGES.PASCAL },
    { value: PROGRAMMING_LANGUAGES.PERL, label: PROGRAMMING_LANGUAGES.PERL },
    { value: PROGRAMMING_LANGUAGES.PHP, label: PROGRAMMING_LANGUAGES.PHP },
    { value: PROGRAMMING_LANGUAGES.PROLOG, label: PROGRAMMING_LANGUAGES.PROLOG },
    { value: PROGRAMMING_LANGUAGES.PYTHON, label: PROGRAMMING_LANGUAGES.PYTHON },
    { value: PROGRAMMING_LANGUAGES.R, label: PROGRAMMING_LANGUAGES.R },
    { value: PROGRAMMING_LANGUAGES.RUBY, label: PROGRAMMING_LANGUAGES.RUBY },
    { value: PROGRAMMING_LANGUAGES.RUST, label: PROGRAMMING_LANGUAGES.RUST },
    { value: PROGRAMMING_LANGUAGES.SCALA, label: PROGRAMMING_LANGUAGES.SCALA },
    { value: PROGRAMMING_LANGUAGES.SQL, label: PROGRAMMING_LANGUAGES.SQL },
    { value: PROGRAMMING_LANGUAGES.SWIFT, label: PROGRAMMING_LANGUAGES.SWIFT },
    { value: PROGRAMMING_LANGUAGES.TEXT, label: PROGRAMMING_LANGUAGES.TEXT },
    { value: PROGRAMMING_LANGUAGES.TYPESCRIPT, label: PROGRAMMING_LANGUAGES.TYPESCRIPT },
    { value: PROGRAMMING_LANGUAGES.VB, label: PROGRAMMING_LANGUAGES.VB },
];
export const STATUSES = {
    ACTIVE: 'Active',
    OPEN_POSITION: 'Open Position',
    CLOSED: 'Closed',
    CLOSED_POSITION: 'Closed Position',
    CANCELED: 'Canceled',
    CAUTIOUSLY_RECOMMENDED: 'Cautiously Recommended',
    IN_PROGRESS: 'In Progress',
    INVITED: 'Invited',
    INVITE_SENT: 'Invite Sent',
    NOT_RECOMMENDED: 'Not Recommended',
    PENDING: 'Pending',
    RECOMMENDED: 'Recommended',
    UNATTENDED: 'Unattended',
    NETWORK_DISCONNECTED: 'Network Disconnected',
    REINVITED: 'Reinvited',
    RESUME_REJECTED: 'Resume Rejected',
    INCOMPLETE_DATA: 'Incomplete Data',
    COMPLETED: 'Completed',
};
