import {
    GET_DASHBOARD_DETAILS,
    GET_DASHBOARD_DETAILS_SUCCESS,
    GET_DASHBOARD_DETAILS_FAILURE,
    GET_BANNERS,
    GET_BANNERS_SUCCESS,
    GET_BANNERS_FAILURE,
} from './types';
export const getDashboardDetails = (payload) => ({
    type: GET_DASHBOARD_DETAILS,
    payload,
});
export const getDashboardDetailsSuccess = (payload) => ({
    type: GET_DASHBOARD_DETAILS_SUCCESS,
    payload,
});
export const getDashboardDetailsFailure = () => ({
    type: GET_DASHBOARD_DETAILS_FAILURE,
});

export const getBanners = () => ({
    type: GET_BANNERS,
});
export const getBannersSuccess = (payload) => ({
    type: GET_BANNERS_SUCCESS,
    payload,
});
export const getBannersFailure = () => ({
    type: GET_BANNERS_FAILURE,
});
